// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sign-activity__subtitle,.sign-activity__title{margin:0 auto;max-width:1280px;position:relative}.sign-activity__info,.sign-activity__scroll{margin:0 auto;max-width:1280px}.sign-activity__scroll{max-height:260px;overflow:auto;position:relative}.sign-activity__scroll::-webkit-scrollbar-thumb{background-color:#dedfe0;display:block}.sign-activity__list{border-radius:10px;-moz-column-gap:46px;column-gap:46px;display:flex;flex-wrap:wrap;padding-left:1px;row-gap:16px}.sign-activity__list .item{align-items:center;border-radius:8px;cursor:pointer;display:flex;flex-direction:column;height:100px;justify-content:space-between;overflow:hidden;position:relative;width:100px}.sign-activity__list .item-mark{height:100%;left:0;position:absolute!important;top:0;width:100%}.sign-activity__list .item-pic{height:50px;position:relative;width:50px}.sign-activity__list .item-number{text-align:center;z-index:10}.sign-activity__list .item-day{font-weight:700;text-align:center}.sign-activity__list .item-name{color:#fff;font-size:12px;line-height:36px}.sign-activity.mobile .sign-activity__scroll{max-height:200px}.sign-activity.mobile .sign-activity__info,.sign-activity.mobile .sign-activity__subtitle,.sign-activity.mobile .sign-activity__title{width:100%}.sign-activity.mobile .sign-activity__list{gap:4px;padding-left:0;width:100%}.sign-activity.mobile .sign-activity__list .item{height:80px;width:24%}.sign-activity.mobile .sign-activity__list .item-pic{height:40px;width:40px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
